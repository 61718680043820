import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: "TrajanPro-Regular";
    src: url("./static/fonts/TrajanPro-Regular.otf") format("otf");
    }
    
    html {
        visibility: hidden;
    }

    html.wf-active,
    html.wf-inactive {
        visibility: visible;
    }

    html, body {
        margin: 0 !important;
        padding: 0 !important;
        height: 100%;
        font-family: 'TrajanPro-Regular', sans-serif;
        font-display: swap;
        /* background-color: #f9f8ee; */
    }

    body {
        display: flex;
        flex-direction: column;
    }

    a {
        color: #f4cb32;
        text-decoration: none;
    }

    img {
        max-width: 100%;
        display: block;
        height: auto;
    }
`;

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
	// eslint-disable-next-line react/jsx-filename-extension
	<>
		<GlobalStyle />
		{element}
	</>
);
